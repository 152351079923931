@import "../website-v2/_variables.less";

// Fallback to other video class use cases 
.layout {
	.video {
		--image-ratio: 56%;
		--border-width: 0.0625rem;
		--border-radius-corner: 0.4855rem;
		--border-radius-inner-corner: calc(var(--border-radius-corner) - var(--border-width));
		--border-radius-outer: var(--border-radius-corner) var(--border-radius-corner) 0 0;
		--border-radius-inner: var(--border-radius-inner-corner) var(--border-radius-inner-corner) 0 0;

		width: 100%;
		border-radius: var(--border-radius-outer);
		border: var(--border-width) solid var(--cyan-300);
		border-bottom: 0;
		box-shadow: var(--video-shadow);
		transition: box-shadow 200ms ease-in-out;
		// TODO: Remove once other video components are removed
		height: initial;
		padding-bottom: initial;

		&:hover {
			box-shadow: var(--video-shadow-hover);
		}

		&--mid-section {
			--border-radius-outer: var(--border-radius-corner);
			--border-radius-inner: var(--border-radius-inner-corner);
			border: var(--border-width) solid var(--cyan-300);
			height: fit-content;
			box-shadow: none;

			&:hover {
				box-shadow: none;
			}
		}

		&__popover {
			max-width: 100rem;
			margin: 0 auto;
			position: relative;

			.video__bg-overlay {
				opacity: 0;
				transition: opacity 200ms ease-in-out;
			}

			&[open] {
				.video__toggle {
					&:after {
						display: none;
					}
				}

				.video__bg-overlay {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					background-color: var(--navy-700);
					opacity: 0.6;
					z-index: 50;
				}

				.video__btn--play {
					display: none;
				}

				.video__btn--close {
					display: flex;
				}
			}
		}

		&__toggle {
			list-style: none;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: var(--navy-700);
				opacity: 0;
				z-index: 10;
				border-radius: var(--border-radius);
				transition: opacity 200ms ease-in-out;
				cursor: pointer;
				border-radius: var(--border-radius-inner);
			}

			&:hover {
				&:before {
					// opacity: 0.2;
				}

				&::after {
					opacity: 0.2;
				}

				.video__btn {
					transform: scale(1.1);

					&--play {

						&::before,
						&::after {
							opacity: 1;
						}
					}
				}
			}

			&:after {
				content: "";
				display: block;
				padding-top: var(--image-ratio);
				background: var(--navy-700);
				opacity: 0.4;
				border-radius: var(--border-radius-inner);
				transition: opacity 200ms ease-in-out;
			}

			.video__default-thumbnail,
			.image-wrapper {
				overflow: hidden;
			}

			.video__default-thumbnail {
				position: absolute;
				object-fit: cover;
				height: 100%;
				width: 100%;
				border-radius: var(--border-radius-inner);
			}

			.image-wrapper {
				position: absolute;
				height: 100%;
				width: 100%;
				margin: 0;
				border-radius: var(--border-radius-inner);

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		&__wrapper {
			.video__cover {
				position: relative;
				width: 100%;
			}

			&:before {
				content: "";
				display: block;
				padding-top: var(--image-ratio);
			}
		}

		&__btn {
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			position: absolute;
			cursor: pointer;
			transition: transform 200ms ease-in-out;

			&--play {
				--play-button-highlight-dimensions: 6.125rem;
				--play-button-dimensions: 13.125rem;

				display: flex;
				width: var(--play-button-dimensions);
				height: var(--play-button-dimensions);
				top: calc(50% - var(--play-button-dimensions)/2);
				left: calc(50% - var(--play-button-dimensions)/2);
				z-index: 10;

				@media screen and (max-width: @screen-sm) {
					--play-button-dimensions-mobile: calc(var(--play-button-dimensions)/2);

					width: var(--play-button-dimensions-mobile);
					height: var(--play-button-dimensions-mobile);
					top: calc(50% - var(--play-button-dimensions-mobile)/2);
					left: calc(50% - var(--play-button-dimensions-mobile)/2);
				}

				&::before,
				&::after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: var(--play-button-highlight-dimensions);
					height: var(--play-button-highlight-dimensions);
					border-radius: 50%;
					z-index: -1;
					opacity: 0.7;
					transition: opacity 200ms ease-in-out;
				}

				&::before {
					--color: var(--video-play-button-before);
					background-color: var(--color);
					box-shadow: 0 0.25rem 0.5625rem var(--color);
				}

				&::after {
					--color: var(--video-play-button-after);
					background-color: var(--color);
					box-shadow: 0 0.260625rem 3.465625rem var(--color);
				}
			}

			&-svg {
				position: relative;
				border-radius: 50%;
				box-shadow: 0 0 54px rgba(255, 255, 255, 0.25);
			}

			&--close {
				display: none;
				// width: 6rem;
				// height: 6rem;
				top: -3.5rem;
				right: -3.5rem;
				z-index: 51;

				@media screen and (max-width: @screen-sm) {
					right: -0.5rem;
					width: 3rem;
					height: 3rem;
				}
			}
		}

		&__embeded {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 51;
			border-radius: var(--border-radius-inner);
			object-fit: fill;
		}
	}
}

.layout .flex-container.stripe:not(.stripe-dark--gradient):has(.video:not(.video--mid-section)) {
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		max-height: 21.3125rem;
		height: 100%;
		width: 100%;
	}

	&:has(.video--purple) {
		&::before {
			background: linear-gradient(180deg, rgba(31, 192, 255, 0.00) 0%, rgba(204, 60, 255, 0.38) 100%);
		}
	}

	&:has(.video--blue) {

		&::before {
			background: var(--hero-section-linear-gradient-darker);
		}
	}
}


.layout .flex-container.stripe:has(.video:not(.video--mid-section)) {
	padding-bottom: 0;
}

.theme {
	&--orange {
		.video {
			--border-width: 0rem;
			--border-radius-corner: 1.286rem;

			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: -10%;
				left: -5rem;
				right: -5rem;
				bottom: 0;
				margin: 0 auto;
				height: auto;
				width: auto;
				border-radius: 50%;
				background: radial-gradient(101.55% 101.55% at 50% -1.55%, rgba(255, 255, 255, 0.28) 0%, rgba(239, 108, 52, 0.36) 18.63%, rgba(204, 60, 255, 0.40) 73.48%, rgba(10, 35, 54, 0.00) 100%);
				filter: blur(10.4rem);
			  }
		}
	}
}